import client from '../../api/client';
import loggedError from '../../api/loggedError';
import CheckoutDeviceMergedConfig from '../CheckoutDeviceMergedConfig';
import FetchCheckoutDeviceOptions from './FetchCheckoutDeviceOptions';

export default async function fetchCheckoutDeviceConfig({
  url,
  clientToken,
  signal,
}: FetchCheckoutDeviceOptions): Promise<CheckoutDeviceMergedConfig> {
  try {
    const response = await client.get(url, {
      headers: {
        'Client-Token': clientToken,
      },
      signal,
    });
    return response.data;
  } catch (e: any) {
    throw loggedError(
      'could_not_fetch_checkout_device_config',
      'Checkout device configuration could not be fetched',
      e,
      { tag: 'Http', error: e.message },
    );
  }
}
