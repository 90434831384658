import projectID from '../ProjectID';

// teegschwendner has a project for each franchise partner
// so we have to use the colors for several project ids
const teegschwendnerColors = {
  '--theme-background': 'transparent',
  '--theme-submit-button': '#BA9E5F',
  '--theme-success': '#80BB32',
  '--theme-svg-main-color': '#BA9E5F',
  '--theme-on-surface': '#BA9E5F',
  '--theme-font-family': '"Fira Sans", sans-serif',
};
const tegutColors = {
  '--theme-background': 'transparent',
  '--theme-submit-button': '#FF5200',
  '--theme-submit-button--darkmode': '#FF5200',
  '--theme-success': '#65BB25',
  '--theme-approve': '#65BB25',
  '--theme-on-surface': '#FF5200',
  '--theme-warning': '#FF5200',
  '--theme-cancel': '#FF5200',
  '--theme-font-family': '"FF Unit Rounded", sans-serif',
  '--theme-svg-main-color': '#FF5200',
  '--theme-feedback-button': '#FF5200',
};
const snabbleSupermarketColors = {
  '--theme-submit-button': '#0077bb',
  '--theme-submit-button--darkmode': '#0077bb',
  '--theme-success': '#66B100',
  '--theme-approve': '#66B100',
  '--theme-on-surface': '#0077bb',
  '--theme-warning': '#C40000',
  '--theme-cancel': '#C40000',
  '--theme-svg-main-color': '#0077bb',
  '--theme-font-family': '"Gilroy", sans-serif',
};
const tchiboColors = {
  '--theme-background': '#F5EDE5',
  '--theme-on-background': '#000000',
  '--theme-on-background-headline': '#C0A468',
  '--theme-submit-button': '#EDD38E',
  '--theme-on-submit-button': '#21292F',
  '--theme-on-background-meta': '#000000',
  '--theme-success': '#719C6F',
  '--theme-svg-main-color': '#C0A468',
  '--theme-on-surface': '#C0A468',
  '--theme-font-family': '"Interstate", sans-serif',
};

export default {
  [projectID.snabbleSupermarket]: snabbleSupermarketColors,
  [projectID.demo]: snabbleSupermarketColors,
  [projectID.teegschwendnerNuernberg]: teegschwendnerColors,
  [projectID.teegschwendnerCompany]: teegschwendnerColors,
  [projectID.teegschwendnerDemo]: teegschwendnerColors,
  [projectID.teegschwendnerMuelheim]: teegschwendnerColors,
  [projectID.teegschwendnerBadenBaden]: teegschwendnerColors,
  [projectID.teegschwendnerHamburg2]: teegschwendnerColors,
  [projectID.teegschwendnerBremen]: teegschwendnerColors,
  [projectID.teegschwendnerHannover]: teegschwendnerColors,
  [projectID.teegschwendnerKoeln]: teegschwendnerColors,
  [projectID.teegschwendnerHamburg]: teegschwendnerColors,
  [projectID.teegschwendnerFrankfurt]: teegschwendnerColors,
  [projectID.teegschwendnerHuerth]: teegschwendnerColors,
  [projectID.teegschwendnerBonn]: teegschwendnerColors,
  [projectID.teegschwendnerGodesberg]: teegschwendnerColors,
  [projectID.teegschwendnerTrier]: teegschwendnerColors,
  [projectID.tegut]: tegutColors,
  [projectID.tegutDemo]: tegutColors,
  [projectID.wanzl]: {
    '--theme-submit-button': '#009CE3',
    '--theme-on-surface': '#009CE3',
    '--theme-success': '#009CE3',
    '--theme-svg-main-color': '#009CE3',
  },
  [projectID.panOston]: {
    '--theme-submit-button': '#036635',
    '--theme-on-surface': '#036635',
    '--theme-on-background': '#fff',
    '--theme-success': '#036635',
    '--theme-svg-main-color': '#036635',
  },
  [projectID.pyramid]: {
    '--theme-submit-button': '#DF006F',
    '--theme-on-surface': '#013062',
    '--theme-success': '#DF006F',
    '--theme-svg-main-color': '#DF006F',
  },
  [projectID.tobaccoland]: {
    '--theme-button': '#fff',
    '--theme-on-button': '#282C5B',
    '--theme-submit-button': '#282C5B',
    '--theme-on-surface': '#282C5B',
    '--theme-success': '#55C882',
    '--theme-svg-main-color': '#282C5B',
  },
  [projectID.gooods]: {
    '--theme-font-family': '"GT Alpina Fine", sans-serif',
    '--theme-on-background-meta': '#000',
    '--theme-success': '#627D66',
    '--theme-svg-main-color': '#627D66',
    '--theme-submit-button': '#fff',
    '--theme-on-submit-button': '#000',
    '--theme-submit-button--darkmode': '#000',
    '--theme-on-submit-button--darkmode': '#fff',
    '--theme-border': '#000',
    '--theme-border--darkmode': '#fff',
  },
  [projectID.maymetzger]: {
    '--theme-submit-button--darkmode': '#fff',
    '--theme-on-submit-button--darkmode': '#000',
  },
  [projectID.tchiboDemo]: tchiboColors,
  [projectID.tchibo]: tchiboColors,
  [projectID.astorGamble]: {
    '--theme-font-family': '"Gilroy", sans-serif',
    '--theme-background': '#e0b49c',
    '--theme-button': '#ffffff',
    '--theme-cancel': 'red',
    '--theme-on-background-headline': '#000000',
    '--theme-on-background-meta': '#8b7162',
    '--theme-on-background': '#000000',
    '--theme-on-button': '#000000',
    '--theme-on-submit-button': '#ffffff',
    '--theme-submit-button': '#000000',
    '--theme-approve': '#red',
    '--theme-on-surface': '#000000',
    '--theme-success': '#000000',
    '--theme-svg-main-color': '#000000',
  },
  [projectID.peekCloppenburgTestlab]: {
    '--theme-font-family': '"Roboto", sans-serif',
    '--theme-on-background-headline': '#292D34',
    '--theme-on-background': '#292D34',
    '--theme-submit-button': '#292D34',
    '--theme-on-surface': '#292D34',
    '--theme-success': '#31AB64',
    '--theme-svg-main-color': '#444444',
  },
  [projectID.hellweg]: {
    '--theme-font-family': '"Roboto", sans-serif',
    '--theme-submit-button': '#A0DAE8',
    '--theme-on-submit-button': '#000000',
    '--theme-svg-main-color': '#494947',
    '--theme-success': '#05A846',
    '--theme-on-background-headline': '#000000',
    '--theme-on-background': '#000000',
    '--theme-on-background-meta': '#000000',
    '--theme-on-surface': '#000000',
  },
  [projectID.hornbach]: {
    '--theme-success': '#008800',
    '--theme-on-background-meta': '#777',
    '--theme-svg-main-color': '#F99E27',
    '--theme-submit-button': '#F99E27',
    '--theme-on-submit-button': '#000',
    '--theme-border': '#777',
    '--theme-on-surface': '#F99E27',
  },
};
