import { lazyWithRetry } from '../lazy';
import projectID from '../ProjectID';
import Registry from '../registry/Registry';
import WizardConfig from './WizardConfig';

const config = new Registry<WizardConfig>();

config.set(
  projectID.tegut,
  {
    startView: lazyWithRetry(() => import('../start/tegut-eixae6/StartView')),
    cartView: lazyWithRetry(() => import('../cart/default/CartView')),
  },
);

config.set(
  projectID.tegutDemo,
  {
    startView: lazyWithRetry(() => import('../start/tegut-eixae6/StartView')),
    cartView: lazyWithRetry(() => import('../cart/default/CartView')),
  },
);

config.set(
  projectID.wanzl,
  {
    startView: lazyWithRetry(() => import('../start/wanzl-mzio7s/StartView')),
    cartView: lazyWithRetry(() => import('../cart/default/CartView')),
  },
);

config.set(
  projectID.snabbleSupermarket,
  {
    startView: lazyWithRetry(() => import('../start/snabble-supermarket-71u283/StartView')),
    cartView: lazyWithRetry(() => import('../cart/default/CartView')),
  },
);

config.set(
  projectID.demo,
  {
    startView: lazyWithRetry(() => import('../start/snabble-supermarket-71u283/StartView')),
    cartView: lazyWithRetry(() => import('../cart/default/CartView')),
  },
);

config.set(
  projectID.tchibo,
  {
    startView: lazyWithRetry(() => import('../start/tchibo-3583dee/StartView')),
    cartView: lazyWithRetry(() => import('../cart/default/CartView')),
    emptyCartPlaceholder: lazyWithRetry(() => import('../cart/tchibo-3583dee/Placeholder')),
  },
);

config.set(
  projectID.tchiboDemo,
  {
    startView: lazyWithRetry(() => import('../start/tchibo-3583dee/StartView')),
    cartView: lazyWithRetry(() => import('../cart/default/CartView')),
    emptyCartPlaceholder: lazyWithRetry(() => import('../cart/tchibo-3583dee/Placeholder')),
  },
);

config.set(
  projectID.teegschwendnerCompany,
  {
    startView: lazyWithRetry(() => import('../start/teegschwendner/StartView')),
    cartView: lazyWithRetry(() => import('../cart/default/CartView')),
  },
);

config.set(
  projectID.teegschwendnerNuernberg,
  {
    startView: lazyWithRetry(() => import('../start/teegschwendner/StartView')),
    cartView: lazyWithRetry(() => import('../cart/default/CartView')),
  },
);

config.set(
  projectID.teegschwendnerDemo,
  {
    startView: lazyWithRetry(() => import('../start/teegschwendner/StartView')),
    cartView: lazyWithRetry(() => import('../cart/default/CartView')),
  },
);

[
  projectID.teegschwendnerMuelheim,
  projectID.teegschwendnerBadenBaden,
  projectID.teegschwendnerHamburg2,
  projectID.teegschwendnerBremen,
  projectID.teegschwendnerHannover,
  projectID.teegschwendnerKoeln,
  projectID.teegschwendnerHamburg,
  projectID.teegschwendnerFrankfurt,
  projectID.teegschwendnerHuerth,
  projectID.teegschwendnerBonn,
  projectID.teegschwendnerGodesberg,
  projectID.teegschwendnerTrier,
].forEach((id) => {
  config.set(
    id,
    {
      startView: lazyWithRetry(() => import('../start/teegschwendner/StartView')),
      cartView: lazyWithRetry(() => import('../cart/default/CartView')),
    },
  );
});

config.set(
  projectID.astorGamble,
  {
    startView: lazyWithRetry(() => import('../start/astor-demo-8h19c0nu/StartView')),
    cartView: lazyWithRetry(() => import('../cart/default/CartView')),
  },
);

config.set(
  projectID.peekCloppenburgTestlab,
  {
    startView: lazyWithRetry(() => import('../start/pc-testlab-guhuus7b/StartView')),
    cartView: lazyWithRetry(() => import('../cart/default/CartView')),
  },
);

config.set(
  projectID.hellweg,
  {
    startView: lazyWithRetry(() => import('../start/hellweg-zi6ayu/StartView')),
    cartView: lazyWithRetry(() => import('../cart/default/CartView')),
  },
);

config.set(
  projectID.hornbach,
  {
    startView: lazyWithRetry(() => import('../start/hornbach-de-orqqd1ag/StartView')),
    cartView: lazyWithRetry(() => import('../cart/default/CartView')),
  },
);

export default config.freeze();
