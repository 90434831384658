import client from '../../api/client';
import AuthenticatedApiOptions from '../../api/AuthenticatedApiOptions';
import loggedError from '../../api/loggedError';

export interface GetPrintableReceiptContentOptions extends AuthenticatedApiOptions {
  url: string;
}

export default async function getPrintableReceiptContent({
  url,
  clientToken,
  signal,
}: GetPrintableReceiptContentOptions) {
  try {
    const response = await client.get<string>(url, {
      headers: {
        Accept: 'text/plain',
        'Client-Token': clientToken,
      },
      signal,
    });
    return response.data;
  } catch (e: any) {
    throw loggedError(
      'could_not_fetch_printable_receipt_content',
      'Printable receipt content could not be fetched',
      e,
      { tag: 'Http', error: e.message },
    );
  }
}
