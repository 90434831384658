import ErrorWithCode from '../error/ErrorWithCode';
import logger from '../logging';
import LogFields from '../logging/LogFields';
import isAbortedRequest from './isAbortedRequestError';

export default function loggedError(
  code: string,
  message: string,
  error?: unknown,
  fields?: LogFields,
) {
  if (!isAbortedRequest(error)) {
    logger.error(message, fields);
  }

  return new ErrorWithCode(code, message, error);
}
