/* eslint-disable no-console */
import initSqlJs from 'sql.js';
import Product from '../models/Product';
import { replaceWithApi } from '../helper/urls';
import normalizeString from '../helper/normalize';
import parseBarcode from '../helper/BarcodeParser';

const ContentTypes = {
  SQLITE3: 'application/vnd+snabble.appdb+sqlite3', // full SQLite3 database for full updates
  SQL: 'application/vnd+snabble.appdb+sql', // list of SQL commands for delta updates
};

const mapScannableCodes = (row) => {
  const scannableCodes = [];
  const lookupCodes = row.lookupCodes.split(',');
  const templates = row.templates.split(',');
  const transmissionCodes = row.transmissionCodes.split(',');
  const codeEncodingUnits = row.codeEncodingUnits.split(',');
  const isPrimary = row.isPrimary.split(',');
  const specifiedQuantity = row.specifiedQuantity.split(',');

  let i = 0;
  lookupCodes.forEach((lookupCode) => {
    scannableCodes.push({
      lookupCode,
      template: templates[i] !== '' ? templates[i] : null,
      transmissionCode: transmissionCodes[i] !== '' ? transmissionCodes[i] : null,
      encodingUnit: codeEncodingUnits[i] !== '' ? codeEncodingUnits[i] : null,
      isPrimary: isPrimary[i] === '1',
      specifiedQuantity: specifiedQuantity[i] !== '' ? parseInt(specifiedQuantity[i], 10) : null,
    });

    i += 1;
  });

  return scannableCodes;
};

function isValidGS1Code(code) {
  if (!code) {
    return false;
  }

  const counts = code.parsedCodeItems.reduce(
    (cs, item) => {
      const currentValue = (cs[item.ai] || 0);
      return { ...cs, [item.ai]: currentValue + 1 };
    },
    {},
  );
  const valid = Object.values(counts).find(count => count === 1);
  return !!valid;
}

function findValueOfAIStartingWith(prefix, code) {
  const ai = code.parsedCodeItems.find(item => item.ai.startsWith(prefix));
  return ai === undefined ? null : ai.data;
}

function checkProductIsValid(product) {
  // NOTE not checking for falsy values since 0 is a valid price
  if (!product.notForSale
    && (product.listPrice === undefined
      || product.listPrice === null
      || product.listPrice === '')
  ) return false;

  return true;
}

class ProductDb {
  init(
    token,
    metadata,
    onFinish = () => { },
    onError = () => { },
    onLogInfo = () => { },
    onLogWarning = () => { },
  ) {
    this.token = token;
    this.setMetadata(metadata);
    this.logInfo = onLogInfo;
    this.logWarning = onLogWarning;

    if (this.db != null) {
      onFinish();
    } else {
      initSqlJs({ locateFile: () => '/sql-wasm.wasm' }).then((SQL) => {
        this.SQL = SQL;
        this.update(() => onFinish(), () => onError());

        if (process.env.NODE_ENV === 'development') {
          window.ProductDb = this;
        }
      });
    }
  }

  setMetadata(metadata) {
    this.project = metadata.project;
    this.shopID = metadata.shop;
    this.currency = metadata.currency || 'EUR';
    this.rounding = metadata.rounding;
    this.decimalDigit = metadata.decimalDigits || 2;
    this.codeTemplates = metadata.codeTemplates;
  }

  updateShop(id) {
    this.shopID = id;
  }

  update(onFinish = () => { }, onError = () => { }, token) {
    console.log('Updating database...');
    if (token) {
      this.token = token;
    }
    let time = performance.now();
    let url = `/${this.project}/appdb`;
    if (this.revision && typeof this.schemaVersionMajor !== 'undefined' && typeof this.schemaVersionMinor !== 'undefined') {
      url += `?havingRevision=${this.revision}&schemaVersion=${this.schemaVersionMajor}.${this.schemaVersionMinor}`;
    }
    const headers = {
      'Client-Token': this.token,
      // NOTE if the accept header is not set the endpoint will always return the full database
      // NOTE even if the sco requests a delta update (SQL) the endpoint might return a full
      // database (SQLITE3). This happens if the database schema changed or the database got too
      // many updates
      Accept: !this.db ? ContentTypes.SQLITE3 : ContentTypes.SQL,
    };
    if (process.env.NODE_ENV !== 'test') {
      headers['cache-control'] = 'no-cache, no-store, max-age=0';
    }

    fetch(replaceWithApi(window.location, url), {
      method: 'GET',
      headers,
    }).then((response) => {
      if (response.status === 304) {
        console.log('Database is already up to date...');
        onFinish();
      } else if (response.status === 200) {
        const isDeltaUpdate = response.headers.get('Content-Type') === ContentTypes.SQL;
        if (isDeltaUpdate) {
          response.text().then((responseString) => {
            let diff = performance.now() - time;
            time = performance.now();
            this.logInfo(`downloaded database delta updates in ${diff.toFixed(2)} ms.`, { tag: 'Analytics' });

            responseString
              .split('\n\n') // NOTE the SQL commands are separated by an empty line
              .filter(Boolean)
              .forEach((part) => {
                this.db.run(part);
              });

            diff = performance.now() - time;
            time = performance.now();
            this.logInfo(`updated database in ${diff.toFixed(2)} ms.`, { tag: 'Analytics' });

            ProductDb.createFullTextSearchIndex(this.db);

            diff = performance.now() - time;
            time = performance.now();
            this.logInfo(`recreated full text search index in ${diff.toFixed(2)} ms.`, { tag: 'Analytics' });

            this.setMetadataFromDB();
            onFinish();
          });
        } else {
          response.arrayBuffer().then((buf) => {
            let diff = performance.now() - time;
            time = performance.now();
            this.logInfo(`downloaded full database in ${diff.toFixed(2)} ms.`, { tag: 'Analytics' });

            const newDb = new this.SQL.Database(new Uint8Array(buf));

            diff = performance.now() - time;
            time = performance.now();
            this.logInfo(`initialized database in ${diff.toFixed(2)} ms.`, { tag: 'Analytics' });

            ProductDb.createFullTextSearchIndex(newDb);

            diff = performance.now() - time;
            time = performance.now();
            this.logInfo(`created full text search index in ${diff.toFixed(2)} ms.`, { tag: 'Analytics' });

            this.db = newDb;
            this.setMetadataFromDB();
            onFinish();
          });
        }
      } else {
        this.logWarning(`error while fetching or initializing appDB: "${url}". Bad status code: "${response.status}"`, { tag: 'App' });
        onError();
      }
    }).catch(() => {
      this.logWarning(`error while fetching or initializing appDB: "${url}"`, { tag: 'Http' });
      onError();
    });
  }

  setMetadataFromDB() {
    this.query('SELECT * from metadata', (row) => {
      this[row.key] = row.value;
    });
  }

  queryFirst(sql) {
    let result = null;
    let isFirst = true;

    this.query(sql, (row) => {
      if (isFirst) {
        isFirst = false;
        result = row;
      }
    });

    return result;
  }

  query(sql, rowHandler) {
    const start = performance.now();
    if (this.db !== undefined && this.db !== null) {
      this.db.each(sql, null, (row) => {
        rowHandler(row);
      }, () => {
        const time = performance.now() - start;
        if (time >= 16) {
          console.log(`Query performance warning (${time.toFixed(2)} ms) for SQL: ${sql}`);
        }
      });
    }
  }

  productQuery(appendSql, rowHandler, distinctSku = false) {
    return this.query(`
      SELECT ${distinctSku ? 'DISTINCT' : ''} p.sku,
      p.name,
      p.description,
      p.imageUrl,
      p.depositSku,
      p.isDeposit,
      p.weighing,
      ifnull((SELECT group_concat(ifnull(s.code, "")) FROM scannableCodes s WHERE s.sku = p.sku), "") AS lookupCodes,
      p.subtitle,
      p.saleRestriction,
      p.saleStop,
      ifnull((SELECT group_concat(ifnull(s.transmissionCode, "")) FROM scannableCodes s WHERE s.sku = p.sku), "") AS transmissionCodes,
      p.referenceUnit,
      p.encodingUnit,
      ifnull((SELECT group_concat(ifnull(s.encodingUnit, "")) FROM scannableCodes s WHERE s.sku = p.sku), "") AS codeEncodingUnits,
      ifnull((SELECT group_concat(ifnull(s.template, "")) FROM scannableCodes s WHERE s.sku = p.sku), "") AS templates,
      ifnull((SELECT group_concat(ifnull(s.isPrimary, '')) FROM scannableCodes s WHERE s.sku = p.sku), "") AS isPrimary,
      ifnull((SELECT group_concat(ifnull(s.specifiedQuantity, '')) FROM scannableCodes s WHERE s.sku = p.sku), "") AS specifiedQuantity,
      p.scanMessage,
      ifnull((SELECT group_concat(ifnull(a.value, "${this.defaultAvailability}")) FROM availabilities a WHERE a.sku = p.sku AND a.shopID = "${this.shopID}"), "${this.defaultAvailability}") as availability,
      p.notForSale
      FROM products p ${appendSql}`, rowHandler);
  }

  queryPrice(sku, shopID) {
    if (this.schemaVersionMinor >= 22 && shopID) {
      return this.queryFirst(`
        SELECT *
        FROM prices
        JOIN shops ON shops.pricingCategory = prices.pricingCategory
        WHERE shops.id = '${shopID}' AND sku = '${sku}'
        ORDER BY priority desc
        LIMIT 1`);
    }

    return this.queryFirst(`
      SELECT listPrice, discountedPrice, customerCardPrice, basePrice
      FROM prices
      WHERE pricingCategory = ifnull((SELECT pricingCategory FROM shops WHERE shops.id = '${shopID}'), '0') AND sku = '${sku}'`);
  }

  rowToProduct(row) {
    const builder = new Product.Builder()
      .withSku(row.sku)
      .withName(row.name)
      .withSubtitle(row.subtitle)
      .withImageUrl(row.imageUrl)
      .withDescription(row.description)
      .withIsDeposit(row.isDeposit !== 0)
      .withType(row.weighing)
      .withSaleRestriction(row.saleRestriction)
      .withSaleStop(row.saleStop !== 0)
      .withReferenceUnit(row.referenceUnit)
      .withEncodingUnit(row.encodingUnit)
      .withScanMessage(row.scanMessage)
      .withAvailability(row.availability)
      .withNotForSale(row.notForSale);

    builder.withScannableCodes(mapScannableCodes(row));

    if (row.depositSku !== null) {
      builder.withDepositProduct(this.findBySku(row.depositSku));
    } else {
      builder.withDepositProduct(null);
    }

    builder.withCurrencyFormattingInformation(this.currency, this.rounding, this.decimalDigit);

    let mappedPrices = this.queryPrice(row.sku, this.shopID);
    if (!mappedPrices || Object.keys(mappedPrices).length === 0) {
      mappedPrices = this.queryPrice(row.sku, 0);
    }

    if (mappedPrices) {
      builder.withListPrice(mappedPrices.listPrice)
        .withBasePrice(mappedPrices.basePrice)
        .withDiscountedPrice(mappedPrices.discountedPrice)
        .withCustomerCardPrice(mappedPrices.customerCardPrice);
    }

    return builder.build();
  }

  // NOTE I think this function is not longer used. It is only referenced in the
  // ShoppingCart class which is not fully utilized.
  findBySku(sku) {
    let result = null;

    this.productQuery(`
     WHERE p.sku = '${sku}'
     AND availability != '${Product.availability.notAvailable}'
     LIMIT 1`, (row) => {
      const product = this.rowToProduct(row);
      if (checkProductIsValid(product)) result = product;
    });

    return result;
  }

  findBySkus(skus) {
    const result = [];
    skus.forEach((sku) => {
      const product = this.findBySku(sku);
      if (product !== null) {
        result.push(product);
      }
    });
    return result;
  }

  findByCode(code) {
    // Parse templates and gs1
    let candidates = this.matchTemplates(code)
      .concat(this.parseGS1Codes(code));

    // Generate possibilities with stripped zeros (at them later?)
    candidates = candidates.concat(this.generateWithStrippedZeros(candidates));
    // Add possibilities with filled zeros
    candidates = candidates.concat(this.generateReplacements(code));

    let result = { product: null, scannedCode: null };
    candidates.find((scannedCode) => {
      const product = this.findByCodeUsingTemplate(scannedCode);

      if (product !== null) {
        result = { product, scannedCode };
        return true;
      }
      return false;
    });

    return result;
  }

  matchTemplates(code) {
    if (!this.codeTemplates) {
      return [];
    }

    const matchingTemplates = this.codeTemplates.findMatchingTemplate(code);

    return matchingTemplates.map(matching => ({
      code: matching.parts.code,
      template: matching.template,
      embed: matching.parts.embed,
      scannedByUser: code,
    }));
  }


  // eslint-disable-next-line class-methods-use-this
  parseGS1Codes(code) {
    // Supported gs1 codes have at least 14 + 2 chars (the AI `01` and a GTIN-14)
    if (code.length < (14 + 2)) {
      return [];
    }

    let gs1Code;
    let aiGTIN;
    try {
      gs1Code = parseBarcode(code.trim());

      if (!isValidGS1Code(gs1Code)) {
        return [];
      }

      aiGTIN = gs1Code.parsedCodeItems.find(item => item.ai === '01');
    } catch (e) {
      // Not a valid gs1 code skip
      return [];
    }

    // Only codes containing a GTIN are supported
    if (aiGTIN === undefined) {
      return [];
    }

    const weight = findValueOfAIStartingWith('310', gs1Code);
    const unitsString = findValueOfAIStartingWith('30', gs1Code);
    const units = unitsString ? parseInt(unitsString, 10) : null;
    const price = findValueOfAIStartingWith('390', gs1Code);

    // Generate alle possible codes (moved to later)
    return {
      code: aiGTIN.data,
      template: 'default',
      scannedByUser: code,
      weight,
      units,
      price,
    };
  }

  // Generate alle possible codes
  // (string, string) => []code
  // eslint-disable-next-line class-methods-use-this
  generateStrippedGTINs(candidate) {
    return [13, 12, 8]
      .map((gtinLength) => {
        const leadingZeros = candidate.code.length - gtinLength;
        if (leadingZeros <= 0 || !candidate.code.startsWith('0'.repeat(leadingZeros))) {
          return null;
        }

        return { ...candidate, code: candidate.code.substr(leadingZeros) };
      })
      .filter(i => i);
  }

  // eslint-disable-next-line class-methods-use-this
  generateWithStrippedZeros(candidates) {
    return candidates
      .map(candidate => this.generateStrippedGTINs(candidate))
      .flat();
  }

  // Generates possible equivalent codes with leading zeros
  generateReplacements(code) {
    if (code.length < 8 || !this.codeTemplates) {
      return [];
    }

    const codes = [];
    let codeWithZeros = code;

    for (let i = code.length; i < 13; i += 1) {
      codeWithZeros = `0${codeWithZeros}`;
      codes.push({
        code: codeWithZeros,
        template: 'default',
        scannedByUser: code,
      });
    }

    return codes;
  }


  findByCodes(codes) {
    const result = [];
    codes.forEach((code) => {
      const r = this.findByCode(code);
      if (r !== null && r.product !== null) {
        result.push(r);
      }
    });
    return result;
  }

  // NOTE this function is used when an item is scanned or a cart category is loaded
  findByCodeUsingTemplate(scannedCode) {
    let result = null;

    this.productQuery(`
      JOIN scannableCodes s
      ON s.sku = p.sku
      WHERE s.code = '${scannedCode.code}'
      AND s.template = '${scannedCode.template}'
      AND availability != '${Product.availability.notAvailable}'
      LIMIT 1`, (row) => {
      const product = this.rowToProduct(row);
      if (checkProductIsValid(product)) result = product;
    });

    return result;
  }

  // Like searchByName the searchBySkuOrEan function
  // lists all possible entries and not just one
  // NOTE all three search... functions are only used for the search functionality
  searchBySkuOrEan(searchQuery) {
    const result = [];

    this.productQuery(
      `
      JOIN scannableCodes s
      ON s.sku = p.sku
      WHERE (
        s.sku LIKE '${searchQuery}%'
        OR s.code LIKE '${searchQuery}%'
      )
      AND p.isDeposit = 0
      AND p.weighing != ${Product.type.userWeighed}
      AND availability != '${Product.availability.notAvailable}'
      AND p.notForSale = 0
      AND s.template = 'default'
      LIMIT 100`,
      (row) => {
        if (row.lookupCodes !== '') {
          const product = this.rowToProduct(row);
          if (checkProductIsValid(product)) result.push(product);
        }
      },
      true,
    );

    return result;
  }

  searchBySkuOrEanOrName(searchQuery) {
    const result = [];

    this.productQuery(
      `
      JOIN scannableCodes s
      ON s.sku = p.sku
      JOIN searchByName ns
      ON ns.sku = p.sku
      WHERE (
        s.sku LIKE '${searchQuery}%'
        OR s.code LIKE '${searchQuery}%'
        OR ns.foldedName LIKE '%${normalizeString(searchQuery)}%'
      )
      AND p.isDeposit = 0
      AND p.weighing != ${Product.type.userWeighed}
      AND availability != '${Product.availability.notAvailable}'
      AND p.notForSale = 0
      AND s.template = 'default'
      LIMIT 100`,
      (row) => {
        if (row.lookupCodes !== '') {
          const product = this.rowToProduct(row);
          if (checkProductIsValid(product)) result.push(product);
        }
      },
      true,
    );

    return result;
  }

  searchByName(name) {
    const result = [];
    this.productQuery(`
      JOIN searchByName ns ON ns.sku = p.sku
      WHERE ns.foldedName MATCH '${normalizeString(name)}*'
      AND p.isDeposit = 0
      AND p.weighing != ${Product.type.preWeighed}
      AND availability != '${Product.availability.notAvailable}'
      AND p.notForSale = 0
      LIMIT 100`, (row) => {
      // items without lookup code cannot be added to cart by now
      // so we will not add them to result
      if (row.lookupCodes !== '') {
        const product = this.rowToProduct(row);
        if (checkProductIsValid(product)) result.push(product);
      }
    });
    return result;
  }

  static createFullTextSearchIndex(db) {
    db.run('PRAGMA synchronous = OFF');
    db.run('PRAGMA journal_mode = MEMORY');

    db.run('DROP TABLE IF EXISTS searchByName');
    db.run('CREATE VIRTUAL TABLE searchByName USING fts4(sku TEXT, foldedName TEXT)');
    const result = db.exec('SELECT sku, name FROM products');

    const params = [];

    if (!result || !result.length) return;

    result[0].values.forEach((value) => {
      const sku = value[0];
      const foldedName = normalizeString(value[1]);
      params.push([sku, foldedName]);
    });

    const preparedStatement = db.prepare('INSERT INTO searchByName (sku, foldedName) VALUES (?, ?)');

    db.run('BEGIN TRANSACTION');

    params.forEach((param) => {
      preparedStatement.run(param);
    });

    db.run('END TRANSACTION');

    preparedStatement.free();
  }
}

export default new ProductDb();
