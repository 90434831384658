import AuthenticatedApiOptions from '../../api/AuthenticatedApiOptions';
import { isSuccessfulOrCustomResponse } from '../../api/statusValidator';
import logger from '../../logging';
import client from './client';

export interface PostHealthStatusOptions extends AuthenticatedApiOptions {
  project: string;
  checkoutDeviceId: string;
  timeout?: number;
  isOnline?: boolean;
  errors?: Array<StatusError>
}

export interface StatusError {
  type: 'ui_component_offline' | 'service_component_offline' | 'terminal_error' | 'printer_error';
  message: string;
}

export default async function postHealthStatus({
  checkoutDeviceId,
  clientToken,
  project,
  signal,
  timeout,
  isOnline = true,
  errors = [],
}: PostHealthStatusOptions) {
  // NOTE the environment variables are injected by webpack
  // it should be set to the git hash before building the app
  const frontendVersion = process.env.REACT_APP_VERSION_INFO;

  // NOTE the url gives us feedback about the currently loaded channel as well
  // as the shop parameter. Using the full url also helps when debugging the
  // service worker, cached files and locally stored data
  const frontendUrl = window.location.href;

  try {
    await client.post(
      `/${project}/checkout-devices/id/${checkoutDeviceId}/status`,
      {
        status: isOnline ? 'UP' : 'DOWN',
        component: 'ui',
        errors,
        systemInfo: {
          ...(frontendVersion ? { frontendVersion } : {}),
          frontendUrl,
        },
      },
      {
        'axios-retry': {
          retries: 0,
        },
        headers: {
          'Client-Token': clientToken,
        },
        signal,
        timeout,
        // regarding the health check, 401 and 403 status codes still mean that
        // the api is working as intended
        validateStatus: status => isSuccessfulOrCustomResponse(status, [401, 403]),
      },
    );

    return true;
  } catch (e: any) {
    logger.error('Health status could not be send', { tag: 'Http', error: e.message });
    throw e;
  }
}
