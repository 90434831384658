import client from '../../api/client';
import AuthenticatedApiOptions from '../../api/AuthenticatedApiOptions';
import Link from '../../link/Link';
import loggedError from '../../api/loggedError';

interface GetSignedDigitalReceiptOptions extends AuthenticatedApiOptions {
  url: string;
}

interface DigitalReceiptLinks {
  signed: Link;
}

interface SignedDigitalReceipt {
  links: DigitalReceiptLinks;
}

export default async function getSignedDigitalReceiptUrl({
  url,
  clientToken,
  signal,
}: GetSignedDigitalReceiptOptions) {
  try {
    const response = await client.get<SignedDigitalReceipt>(`${url}/sign`, {
      headers: {
        'Client-Token': clientToken,
      },
      signal,
    });
    return response.data.links.signed.href;
  } catch (e: any) {
    throw loggedError(
      'could_not_fetch_signed_digital_receipt_url',
      'Signed digital receipt URL could not be fetched',
      e,
      { tag: 'Http', error: e.message },
    );
  }
}
