import client from '../../api/client';
import loggedError from '../../api/loggedError';
import Coupon from '../Coupon';

export interface FetchCouponsOptions {
  url: string;
  clientToken: string;
}

export default async function fetchCoupons({
  url,
  clientToken,
}: FetchCouponsOptions): Promise<Coupon[]> {
  try {
    const response = await client.get(url, {
      headers: {
        'Client-Token': clientToken,
      },
    });
    return response.data.coupons;
  } catch (e: any) {
    throw loggedError(
      'could_not_fetch_coupons',
      'Coupons could not be fetched',
      e,
      { tag: 'Http', error: e.message },
    );
  }
}
