import React, { Suspense } from 'react';
import { lazyWithRetry } from '../lazy';
import projectID from '../ProjectID';

// AL: Finally I found a way for conditional SCSS Imports with lazy and suspense <3
// https://prawira.medium.com/react-conditional-import-conditional-css-import-110cc58e0da6
const DefaultFonts = lazyWithRetry(() => import('./default/Fonts'));
const TegutEixae6Fonts = lazyWithRetry(() => import('./tegut-eixae6/Fonts'));
const Migrolino9ef12eFonts = lazyWithRetry(() => import('./migrolino-9ef12e/Fonts'));
const SnabbleSupermarket71u283Fonts = lazyWithRetry(() => import('./snabble-supermarket-71u283/Fonts'));
const Tchibo3583deeFonts = lazyWithRetry(() => import('./tchibo-3583dee/Fonts'));
const TeegschwendnerFonts = lazyWithRetry(() => import('./teegschwendner/Fonts'));
const HornbachFonts = lazyWithRetry(() => import('./hornbach-de-orqqd1ag/Fonts'));

function renderFontsForProject(project: string) {
  switch (project) {
    case projectID.tegut:
    case projectID.tegutDemo:
      return <TegutEixae6Fonts />;
    case projectID.gooods:
      return <Migrolino9ef12eFonts />;
    case projectID.snabbleSupermarket:
    case projectID.demo:
      return <SnabbleSupermarket71u283Fonts />;
    case projectID.tchibo:
    case projectID.tchiboDemo:
      return <Tchibo3583deeFonts />;
    case projectID.teegschwendnerCompany:
    case projectID.teegschwendnerNuernberg:
    case projectID.teegschwendnerDemo:
    case projectID.teegschwendnerMuelheim:
    case projectID.teegschwendnerBadenBaden:
    case projectID.teegschwendnerHamburg2:
    case projectID.teegschwendnerBremen:
    case projectID.teegschwendnerHannover:
    case projectID.teegschwendnerKoeln:
    case projectID.teegschwendnerHamburg:
    case projectID.teegschwendnerFrankfurt:
    case projectID.teegschwendnerHuerth:
    case projectID.teegschwendnerBonn:
    case projectID.teegschwendnerGodesberg:
    case projectID.teegschwendnerTrier:
      return <TeegschwendnerFonts />;
    case projectID.hornbach:
      return <HornbachFonts />;
    default:
      return <DefaultFonts />;
  }
}

interface ThemeSpecificFontsProps {
  project: string;
}

export default function ThemeSpecificFonts({ project }: ThemeSpecificFontsProps) {
  return (
    <Suspense fallback={<React.Fragment />}>
      {renderFontsForProject(project)}
    </Suspense>
  );
}

